<template>
	<v-card align="start" elevation="10" height="365">
		<v-card-text>
			<div
				align="center"
				class="subtitle-1 font-weight-bold greyDarken4--text text-uppercase text-truncate"
			>
				{{ $t("app.your_new_notifications") }}
			</div>
			<div align="center" class="text-truncate">
				{{ $t("app.new_notifications_desc") }}
			</div>

			<div v-if="!newNotifications.length" class="mt-15 pt-10" align="center">
				<v-img width="60" :src="require('@/assets/images/bell.png')"></v-img>
				<div class="mt-7 subtitle-1 greyDarken4--text">
					{{ $t("app.no_new_notifications") }}
				</div>
			</div>

			<v-list two-line tile v-else class="mt-5">
				<template v-for="(item, index) in newNotifications">
					<v-list-item :key="index">
						<v-list-item-icon>
							<v-icon color="info">$vuetify.icons.value.bell </v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<!-- Message owner -->
							<v-list-item-title>
								{{ item.sender.name }}
							</v-list-item-title>

							<!-- Message subtitle -->
							<v-list-item-subtitle
								v-html="item.msg_title"
							></v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action justify="center">
							<v-list-item-action-text>
								{{ moment(item.create_date).fromNow() }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
				</template>
			</v-list>

			<v-footer
				v-if="inbox.length && $route.name != 'Projects'"
				absolute
				bottom
				color="white"
			>
				<AppButton
					label="app.view_all_notifications"
					color="blueLighten5"
					:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
					:block="true"
					:class="['mb-1', 'font-weight-bold', 'info--text']"
					@click="redirectTo()"
				></AppButton>
			</v-footer>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
	name: "DashboardNotifications",

	data() {
		return {
			moment,
		};
	},

	computed: {
		...mapState({
			inbox: (state) => state.notification.inbox,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
		}),

		newNotifications() {
			return this.inbox.filter((item) => item.msg_new).slice(0, 3);
		},
	},

	methods: {
		redirectTo() {
			this.$router.push({
				name: this.isMentor ? "MentorNotifications" : "Notifications",
			});
		},
	},
};
</script>

<style></style>
