<template>
	<div>
		<!-- Profile image -->
		<v-avatar color="greyLighten2" size="55" id="image">
			<v-img
				v-if="userProfile.avatar && !userProfile.avatar.includes('female1')"
				:src="`${$environments.base_api_url}${userProfile.avatar}`"
			></v-img>
			<span v-else class="text-h5">{{ fullName()[0] }}</span>
		</v-avatar>

		<!-- Introduction -->
		<div class="ms-15 ps-7">
			<div class="greyDarken4--text text-h5 font-weight-bold">
				{{
					$t("app.hello", {
						name: greetingName,
					})
				}}
			</div>
			<div class="font-weight-bold" v-html="$t(subheading)"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "DashboardIntroduction",

	props: {
		subheading: {
			default: "app.glad_to_have_onboard",
		},
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
			fullName: "user/fullName",
			getFirstName: "user/getFirstName",
		}),

		greetingName() {
			let first_name = this.getFirstName();
			return first_name ? first_name : this.fullName();
		},
	},
};
</script>

<style scoped>
#image {
	background-size: cover;
	border-radius: 50% 50% 50% 50%;
	outline: 3px solid var(--v-greenAccent2-base);
	width: 55px;
	height: 55px;
	position: absolute;
}
</style>
